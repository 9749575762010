import { axiosInstance } from '@/shared/api/axios';
import { TableResultType } from '@/shared/types/api.type';
import {
  CustomerBulkUploadPostRequestType,
  CustomerBulkUploadPostResponseType,
  CustomerDuplicateCheckPostRequestType,
  CustomerDuplicateCheckPostResponseType,
  CustomerDeleteRequestType,
  CustomerDeleteResponseType,
  CustomerGetRequestType,
  CustomerGetResponseType,
  CustomerPostRequestType,
  CustomerPostResponseType,
  CustomerPutRequestType,
  CustomerPutResponseType,
  CustomerFileDownloadResponseType,
  CustomerFileDownloadPostRequestType,
  CustomBulkSettingGetRequestType,
  CustomBulkSettingGetResponseType,
  CustomerCustomBulkUploadPostRequestType,
  CustomerCustomBulkUploadPostResponseType,
} from '@/shared/types/api/api.customer';
import { replaceAllSpaceToHalfSpace } from '@/shared/utils/space';

export const searchCustomers = async ({
  groupId,
  folderId,
  pageSize,
  pageIndex,
  sortTarget,
  sortOrder,
  customerName,
  checkedAt,
  isNotExecutedUntilNow,
}: CustomerGetRequestType): Promise<TableResultType<CustomerGetResponseType[]>> => {
  const { data } = await axiosInstance.post<TableResultType<CustomerGetResponseType[]>>(
    `/group/${groupId}/customer/search`,
    {
      checkedAt,
      isNotExecutedUntilNow,
      customerNames: customerName
        ? replaceAllSpaceToHalfSpace(customerName)
            .split(' ')
            .filter((d) => d !== '')
        : [],
    },
    {
      params: {
        folderId,
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
        sortTarget,
        sortOrder,
      },
    },
  );
  return data;
};

export const createCustomers = async ({
  groupId,
  customers,
  executionConditionId,
  folderId,
  folderName,
  removeSpaceInCustomerName,
  duplicateCheckInRelativeWord,
}: CustomerPostRequestType): Promise<CustomerPostResponseType> => {
  const { data } = await axiosInstance.post<CustomerPostResponseType>(
    `/group/${groupId}/customer/individual`,
    {
      customers,
      executionConditionId,
      folderId,
      folderName,
      removeSpaceInCustomerName,
      duplicateCheckInRelativeWord,
    },
  );

  return data;
};

export const updateCustomer = async ({
  groupId,
  customerId,
  executionConditionId,
  relativeWord,
}: CustomerPutRequestType): Promise<CustomerPutResponseType> => {
  const { data } = await axiosInstance.put<CustomerPutResponseType>(
    `/group/${groupId}/customer/${customerId}`,
    {
      executionConditionId,
      relativeWord,
    },
  );

  return data;
};

export const deleteCustomers = async ({
  groupId,
  customerIds,
}: CustomerDeleteRequestType): Promise<CustomerDeleteResponseType> => {
  const { data } = await axiosInstance.post<CustomerDeleteResponseType>(
    `/group/${groupId}/customer/delete`,
    {
      customerIds,
    },
  );

  return data;
};

export const downloadCustomerFiles = async ({
  groupId,
  customerIds,
  folderId,
}: CustomerFileDownloadPostRequestType): Promise<CustomerFileDownloadResponseType> => {
  const { data } = await axiosInstance.post<CustomerFileDownloadResponseType>(
    `/group/${groupId}/customer/file`,
    {
      customerIds,
      folderId,
    },
  );

  return data;
};

export const bulkUpdateCustomers = async ({
  groupId,
  customers,
  removeSpaceInCustomerName,
  duplicateCheckInRelativeWord,
  fileName,
}: CustomerBulkUploadPostRequestType): Promise<CustomerBulkUploadPostResponseType> => {
  const { data } = await axiosInstance.post<CustomerBulkUploadPostResponseType>(
    `/group/${groupId}/customer/bulk`,
    {
      customers,
      fileName,
      removeSpaceInCustomerName,
      duplicateCheckInRelativeWord,
    },
    {
      timeout: 300000,
    },
  );

  return data;
};

export const customBulkUpdateCustomers = async ({
  groupId,
  customers,
  removeSpaceInCustomerName,
  duplicateCheckInRelativeWord,
}: CustomerCustomBulkUploadPostRequestType): Promise<CustomerCustomBulkUploadPostResponseType> => {
  const { data } = await axiosInstance.post<CustomerCustomBulkUploadPostResponseType>(
    `/group/${groupId}/customer/customBulk`,
    {
      customers,
      removeSpaceInCustomerName,
      duplicateCheckInRelativeWord,
    },
    {
      timeout: 300000,
    },
  );

  return data;
};

export const duplicateCheckCustomers = async ({
  groupId,
  customers,
  removeSpaceInCustomerName,
}: CustomerDuplicateCheckPostRequestType): Promise<CustomerDuplicateCheckPostResponseType> => {
  const { data } = await axiosInstance.post<CustomerDuplicateCheckPostResponseType>(
    `/group/${groupId}/customer/duplicate-check`,
    {
      customers,
      removeSpaceInCustomerName,
    },
    {
      timeout: 300000,
    },
  );

  return data;
};

export const getCustomBulkSetting = async ({
  groupId,
}: CustomBulkSettingGetRequestType): Promise<CustomBulkSettingGetResponseType> => {
  const { data } = await axiosInstance.get<CustomBulkSettingGetResponseType>(
    `/group/${groupId}/customer/customBulkSetting`,
    {},
  );
  return data;
};
