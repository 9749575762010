import React, { useCallback } from 'react';

import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  ContentStyle,
  TextInnerGroupStyle,
  TextOuterGroupStyle,
  TextStyle,
} from '@/components/FunctionalRestrictionDialog/css';
import { authInformationState } from '@/recoil/atoms/authInformationState';
import { functionalRestrictionDialogSelector } from '@/recoil/selectors/functionalRestrictionDialogSelector';
import Button from '@/shared/components/Button';
import ModalTemplate from '@/shared/components/DialogTemplate';
import { authCode } from '@/shared/constants/code';

const FunctionalRestrictionDialog: React.FC = () => {
  const { handleClose, open } = useRecoilValue(functionalRestrictionDialogSelector);
  const setDialogDialogState = useSetRecoilState(functionalRestrictionDialogSelector);

  const onClose = useCallback(() => {
    setDialogDialogState({ open: false });
    handleClose && handleClose();
  }, [handleClose, setDialogDialogState]);

  const { authority } = useRecoilValue(authInformationState);
  const height = 50;
  const width = 200;
  return (
    <ModalTemplate
      open={open}
      handleClose={onClose}
      width={900}
      title="こちらの機能は「ミニマム契約」「定額契約」でご利用が可能です。"
      contents={
        <ContentStyle>
          <TextOuterGroupStyle>
            <CheckBoxOutlinedIcon sx={{ fontSize: 40, margin: 'auto' }} />
            <TextInnerGroupStyle>
              <TextStyle>ミニマム契約（インターネット検索のみ）：</TextStyle>
              <TextStyle>最低利用料金：5,000円</TextStyle>
            </TextInnerGroupStyle>
          </TextOuterGroupStyle>
          <br />
          <TextOuterGroupStyle>
            <CheckBoxOutlinedIcon sx={{ fontSize: 40, margin: 'auto' }} />
            <TextInnerGroupStyle>
              <TextStyle>ミニマム契約（インターネット+新聞記事検索）：</TextStyle>
              <TextStyle>最低利用料金：7,000円 + 新聞記事閲覧費用</TextStyle>
            </TextInnerGroupStyle>
          </TextOuterGroupStyle>
          <br />
          <TextOuterGroupStyle>
            <CheckBoxOutlinedIcon sx={{ fontSize: 40, margin: 'auto' }} />
            <TextInnerGroupStyle>
              <TextStyle>定額契約（月間・年間契約）：</TextStyle>
              <TextStyle>ご利用想定件数に応じて、最適な単価で個別見積させていただきます</TextStyle>
              <TextStyle fontSize="1.2em" fontWeight={400}>
                ※お申し込み希望は問合せにて受け付けておりますのでご連絡ください。
              </TextStyle>
            </TextInnerGroupStyle>
          </TextOuterGroupStyle>
        </ContentStyle>
      }
      footer={
        <div>
          {[authCode.authority.OWNER].includes(authority || 0) && (
            <Button
              text="ミニマム契約 詳細ページ"
              variant="outlined"
              height={height}
              width={width}
              onClick={() => window.open(import.meta.env.VITE_FAQ_MINIMUM_URL)}
            />
          )}
          {[authCode.authority.OWNER].includes(authority || 0) && (
            <Button
              text="定額契約 お問い合わせ"
              variant="outlined"
              marginLeft={15}
              height={height}
              width={width}
              onClick={() => window.open(import.meta.env.VITE_REQUESTS_URL)}
            />
          )}
          {[
            authCode.authority.ALL,
            authCode.authority.EXECUTE,
            authCode.authority.READONLY,
          ].includes(authority || 0) && (
            <Button
              text="お問い合わせ"
              variant="outlined"
              marginLeft={15}
              height={height}
              width={width}
              onClick={() => window.open(import.meta.env.VITE_REQUESTS_URL)}
            />
          )}
        </div>
      }
    />
  );
};

export default FunctionalRestrictionDialog;
